<mat-toolbar color="primary" class="expanded-toolbar">
    <span>
      <button mat-button routerLink="" routerLinkActive="active">
	      {{title}}
	    </button>
    </span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm>

        <button class= "block" mat-button routerLink="info"> {{navComponentItems.info}}</button>
				<mat-form-field appearance="outline">
				   <mat-icon>language</mat-icon>
					 <mat-select (selectionChange)="onSelectionChange($event)" placeholder=""[(value)]="selectedLanguage">
                             <mat-option  *ngFor="let language of languageList" [value]="language">
                                                                  {{language}}
                             </mat-option>

           </mat-select>
				</mat-form-field>
		    <!--</button>-->
    </div>

    <button mat-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
     <mat-icon>menu</mat-icon>
    </button>
</mat-toolbar>
<mat-menu x-position="before" #menu="matMenu">

        <div>

          <button mat-button routerLink="info"> {{navComponentItems.info}}</button>


        </div>
		    <!--<button mat-button routerLink="/getAllLanguage">-->

				<mat-form-field appearance="outline">
				<mat-icon>language</mat-icon>
					 <mat-select (selectionChange)="onSelectionChange($event)" placeholder=""[(value)]="selectedLanguage">
                             <mat-option  *ngFor="let language of languageList" [value]="language">
                                                                  {{language}}
                             </mat-option>

                     </mat-select>
				</mat-form-field>
		    <!--</button>-->


</mat-menu>
<router-outlet></router-outlet>
