import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router } from '@angular/router';


declare var $:any; 
@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.scss']
})
export class AdminloginComponent implements OnInit {

  adminData = {'token': '', 'email': '', 'password': ''} ;
 // public _rdata: number;
 // public _tkn: string;
  constructor(public adminApi : AdminService, private _route : Router ) { }

  ngOnInit() {
    if (this.adminApi.getLoggedU && this.adminApi.getToken){
      this._route.navigate(['/admin'])
    }
    }
  //loginAdmin = () => console.log(this.adminData)
  loginAdmin() {
    if (this.adminData.email && this.adminData.password == '12345') {
      $.LoadingOverlay("show");
    this.adminApi.verifyUD(this.adminData, 1)
    .subscribe((resptkn) => {
      if (resptkn.tkn) {
        $('*').find('#info3').text('LOGGING IN...');
        $.LoadingOverlay("hide");
        localStorage.setItem('admin_token',resptkn.tkn )
        localStorage.setItem('admin_email',this.adminData.email )
        this._route.navigate(['/admin'])
    }
    else {
      $.LoadingOverlay("hide");
      $('*').find('#info3').text('WRONG USERNAME OR PASSWORD');
      this._route.navigate(['/adminlogin'])
    }
      })
      $.LoadingOverlay("hide");  
  }  else {
    $.LoadingOverlay("hide");
    $('*').find('#info3').text('CORRECT USERNAME AND PASSWORD REQUIRE');
   }

  }

} // class