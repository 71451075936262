import { feedback } from './feedback';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Questions } from './Questions';
import { Observable, of, Subject, throwError } from 'rxjs';
import { retry, catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { adm } from './adm';


@Injectable({
  providedIn: 'root'
})


export class AdminService {
 admin_API_URL = 'https://mep.lut.fi/pb/';
  //admin_API_URL = 'http://127.0.0.1:5000/';
  log: any;
  private _rd: boolean =  false;
  public data__: any = [];
  public em;



  constructor(private http: HttpClient, private ar : Router) { }

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
      })
    }

  // HttpClient API get() method => Fetch questions
  getQuestions(): Observable<Questions> {
    return this.http.get<Questions>(`${this.admin_API_URL}/showall`,this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
 }//  Fetch feedback details
 userFeedback(): Observable<any> {
  return this.http.get<any>(`${this.admin_API_URL}/feedback`,this.httpOptions)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

 //  Fetch feedback details
 getFeedback(): Observable<feedback> {
  return this.http.get<feedback>(`${this.admin_API_URL}/showfeedback`,this.httpOptions)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

 // send email report
 emailReport(id: number, report: any): Observable<any> {
  return this.http.post<any>(this.admin_API_URL + '/sendm/' + id, report, this.httpOptions)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

 // updating questions
 sendQuestion(id: string, updated_question: string): Observable<Questions> {
  return this.http.put<Questions>(this.admin_API_URL + '/updatequestion/' + id, updated_question, this.httpOptions)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

 // updating feedback
 sendFeedBack(id: string, u_feedback: string): Observable<feedback> {
  return this.http.put<feedback>(this.admin_API_URL + '/updatefeedback/' + id, u_feedback, this.httpOptions)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}


  // verify userd data
  verifyUD(adminData: { token: string; email: string; password: string; } , mid: number): Observable<any> { adminData.password='';
    return this.http.post<any>(this.admin_API_URL + '/verifyuser/' + mid, adminData, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleAdminLoginError)
    )
}
  handleVUError(error)  { // handling user verificatino errors
    let errorMessage = 'unfortunately there is an error';
      if(error.error instanceof ErrorEvent) {
        //console.log('client side '+ error.error)
        errorMessage = error.message;
      } else {
        //console.log('server side '+ error.statusText)
        //errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        if (error.status == 500 || error.status == 401)
        localStorage.removeItem('admin_token')
        localStorage.removeItem('admin_email')
        location.reload()
    }
     // window.alert(errorMessage)
    return throwError(error.statusText);

  }

  getToken = () => {return localStorage.getItem('admin_token')}
  getLoggedU = () => {return localStorage.getItem('admin_email')}

  loggedIn = () => {
    //this.verifyLogedinU();return this._rd
   return !!localStorage.getItem('admin_token')
  }

  verifyLogedinU(): Observable<String> {
    if (this.loggedIn() && this.getLoggedU()) {
    var subject = new Subject<string>();
    const tmp = {'password': '', 'email': this.getLoggedU(), 'token': this.getToken()}
    this.http.post(this.admin_API_URL + '/verifyuser/3', tmp, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleAdminLoginError)
    )
    .toPromise().then(data  => { this.em = data;
      subject.next(this.em);
    })
    return subject.asObservable();
  }
  else {
    this.ar.navigate(['/adminlogin']);
  }
}
getTokenData(): Observable<any> {
  if (this.loggedIn() && this.getLoggedU()) {
  const tmp = {'password': '', 'email': this.getLoggedU(), 'token': this.getToken()}
  return this.http.post(this.admin_API_URL + '/verifyuser/2', tmp, this.httpOptions)
  .pipe(
    retry(1),
    catchError(this.handleVUError)
  )
}}
  logoutU = () => {localStorage.removeItem('admin_token');localStorage.removeItem('admin_email')
        this._rd = false
        this.ar.navigate(['/home'])
          }
     // Error handling
     handleError(error) {
      let errorMessage = 'unfortunately there is an error';
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        if (error.status == 500 || error.status == 401)
        localStorage.removeItem('admin_token')
        localStorage.removeItem('admin_email')
        location.reload()
      }
     // window.alert(errorMessage)
      return throwError(errorMessage);
   }
   handleAdminLoginError(error){
     let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // client-side error 
      errorMessage = error.message
    } else {
      // Server-side error make login attempt secure
      if (error.status == 500 || error.status == 401)
      localStorage.removeItem('admin_token')
      localStorage.removeItem('admin_email')
      location.reload()
    }
    return throwError(errorMessage);

   }
}
