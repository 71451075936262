import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class AppComponent {
    constructor(rest, route, router, data) {
        this.rest = rest;
        this.route = route;
        this.router = router;
        this.data = data;
        this.title = 'Lukiomittari';
        this.staticPageContent = [];
        this.languageList = [];
        this.selectedLanguage = 'FIN';
        this.mapComponentList = [];
        this.navComponentItems = {};
        this.homeComponentItems = {};
        this.infoComponentItems = {};
        this.faqComponentItems = {};
        this.questionComponentItems = {};
        this.ComponentListByLang = [];
        this.ComponentListAll = [];
        this.DataTransferMessage = [];
    }
    ngOnInit() {
        // console.log('Inside ngOnInit in app component caller');
        this.selectedLanguage = 'FIN';
        this.getAllLanguage();
    }
    delay(ms) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('fired'));
        });
    }
    //
    getAllLanguage() {
        // console.log('Inside getAllLanguage in app component caller');
        this.languageList = [];
        this.rest.getAllLanguage().subscribe((data) => {
            // console.log('logging language list');
            // console.log(data);
            this.languageList = data;
            this.getAllStaticContent();
        });
    }
    getAllStaticContent() {
        // console.log('Inside getAllStaticContent in app component caller');
        this.staticPageContent = [];
        this.mapComponentList = [];
        this.rest.getAllStaticContent().subscribe((data) => {
            // console.log('logging static content');
            // console.log(data);
            this.staticPageContent = data;
            this.mapStaticData(data, this.selectedLanguage, this.languageList, this.ComponentListAll, this.ComponentListByLang);
            this.data.changeLanguage(this.selectedLanguage);
            this.switchLanguage(this.selectedLanguage, this.ComponentListAll);
            this.data.changeMessage(this.homeComponentItems);
            this.data.changeQuestionPageStatic(this.questionComponentItems);
            this.data.changeInfoPageStatic(this.infoComponentItems);
        });
    }
    onSelectionChange(lang) {
        // console.log('----language--:', lang.value);
        this.selectedLanguage = lang.value;
        this.data.changeLanguage(this.selectedLanguage);
        this.switchLanguage(this.selectedLanguage, this.ComponentListAll);
    }
    switchLanguage(selectedLanguage, ComponentListAll) {
        switch (selectedLanguage) {
            case 'ENG':
                this.replaceLanguageText(selectedLanguage, ComponentListAll);
                break;
            case 'FIN':
            default:
                this.replaceLanguageText(selectedLanguage, ComponentListAll);
                break;
        }
    }
    replaceLanguageText(selectedLanguage, ComponentListAll) {
        this.navComponentItems.info = ComponentListAll[0].component_list.language_list[selectedLanguage][0];
        this.navComponentItems.faq = ComponentListAll[0].component_list.language_list[selectedLanguage][1];
        this.navComponentItems.admin = ComponentListAll[0].component_list.language_list[selectedLanguage][2];
        this.navComponentItems.login = ComponentListAll[0].component_list.language_list[selectedLanguage][3];
        this.navComponentItems.register = ComponentListAll[0].component_list.language_list[selectedLanguage][4];
        //
        this.homeComponentItems.instruction = ComponentListAll[1].component_list.language_list[selectedLanguage][0];
        this.homeComponentItems.usermessage = ComponentListAll[1].component_list.language_list[selectedLanguage][1];
        this.homeComponentItems.contact = ComponentListAll[1].component_list.language_list[selectedLanguage][2];
        this.homeComponentItems.startbutton = ComponentListAll[1].component_list.language_list[selectedLanguage][3];
        //
        this.questionComponentItems.questionInstruction = ComponentListAll[2].component_list.language_list[selectedLanguage][0];
        this.questionComponentItems.backButton = ComponentListAll[2].component_list.language_list[selectedLanguage][1];
        this.questionComponentItems.nextButton = ComponentListAll[2].component_list.language_list[selectedLanguage][2];
        this.questionComponentItems.submitButton = ComponentListAll[2].component_list.language_list[selectedLanguage][3];
        //
        this.infoComponentItems.infoHeader = ComponentListAll[3].component_list.language_list[selectedLanguage][0];
        this.infoComponentItems.infoText1 = ComponentListAll[3].component_list.language_list[selectedLanguage][1];
        this.infoComponentItems.infoText2 = ComponentListAll[3].component_list.language_list[selectedLanguage][2];
        this.infoComponentItems.infoText3 = ComponentListAll[3].component_list.language_list[selectedLanguage][3];
        this.infoComponentItems.infoTextEnd = ComponentListAll[3].component_list.language_list[selectedLanguage][4];
        this.infoComponentItems.infoContact1 = ComponentListAll[3].component_list.language_list[selectedLanguage][5];
        this.infoComponentItems.infoContact2 = ComponentListAll[3].component_list.language_list[selectedLanguage][6];
        this.infoComponentItems.infoContact3 = ComponentListAll[3].component_list.language_list[selectedLanguage][7];
        this.infoComponentItems.infoContact4 = ComponentListAll[3].component_list.language_list[selectedLanguage][8];
        this.infoComponentItems.infoDataSecurity = ComponentListAll[3].component_list.language_list[selectedLanguage][9];
        this.infoComponentItems.infoDataSecurityBtn = ComponentListAll[3].component_list.language_list[selectedLanguage][10];
    }
    // This function creates a array of static content using language as key. The array constructed is dynamic.
    // [
    // {
    // "ENG":["Info","FAQ","Admin","Log In","Log In","Feedback","Measure My Teaching","Change Password","Log Out"],
    // "FIN":["Tietoa","FAQ","Admin","Kirjaudu sisään","Rekisteröidy","Palaute","Mittaristo","Vaihda salasana","Kirjaudu ulos"],
    // "component_name":"pagelayout","page_name":"index"}}]
    mapStaticData(data, selectedLanguage, languageList, ComponentListAll, ComponentListByLang) {
        for (const pages of data) {
            const tempCompenentobj = {
                component_list: undefined,
                page_name: undefined
            };
            // console.log('************************** In for a New Page ************************************** ');
            tempCompenentobj.page_name = pages.mtee_page_name;
            tempCompenentobj.component_list = {};
            for (const componentList of pages.mtee_page_component_list) {
                // console.log('Reading page ComponentList');
                tempCompenentobj.component_list.component_name = componentList.mtee_page_component_name;
                tempCompenentobj.component_list.language_list = {};
                for (const itemList of componentList.mtee_page_component_item_list) {
                    // console.log('Reading page component itemList');
                    for (const componentDisplay of itemList.mtee_page_component_item_display) {
                        // console.log(JSON.stringify(componentDisplay));
                        for (const language of languageList) {
                            // console.log(JSON.stringify(language));
                            // console.log("***********************************");
                            if (componentDisplay[language]) {
                                ComponentListByLang.push(componentDisplay[language]);
                                // console.log(JSON.stringify(ComponentListByLang));
                                // console.log('++++++++++++++++++++++++++++++++++');
                                // console.log(JSON.stringify(tempCompenentobj['component_list']['language_list'][language]));
                                if (tempCompenentobj.component_list.language_list[language] == null) {
                                    tempCompenentobj.component_list.language_list[language] = [];
                                    tempCompenentobj.component_list.language_list[language].push(ComponentListByLang[0]);
                                    // console.log('First time for this language ---------------> ' + JSON.stringify(language));
                                }
                                else {
                                    tempCompenentobj.component_list.language_list[language].push(ComponentListByLang[0]);
                                    // console.log('adding item for this language -> ' + JSON.stringify(language));
                                }
                                // console.log(JSON.stringify(tempCompenentobj));
                                // console.log('//////////////////////////////////');
                                ComponentListByLang = [];
                            }
                        }
                    }
                }
            }
            ComponentListAll.push(tempCompenentobj);
            // console.log(JSON.stringify(ComponentListAll));
        }
    }
}
