import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {map, catchError, tap, retry} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';

const headers = new HttpHeaders();
headers.set('Content-Type', 'application/json; charset=utf-8');
headers.set('Accept', 'application/json');
headers.set('Access-Control-Allow-Origin', '*');

const endpoint = 'https://mep.lut.fi/pb/';
//const endpoint = 'http://localhost:7000/';
@Injectable({
  providedIn: 'root'
})

export class WebserviceCallerService {

  constructor(private http: HttpClient) { }

   // Http Options
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json'
        })
      };
//
  private ResponseContentType: any;


  private extractData(res: Response) {
  const body = res['result'];
  // console.log('Inside extractData in webservice caller');
  return body || { };
}

 public getAllLanguage(): Observable<any> {
    // console.log('Inside getAllLanguage in webservice caller');
    return this.http.get(endpoint + 'getAllLanguage').pipe(map(this.extractData));
}
public checkSurveyStatus(): Observable<any> {
    // console.log('Inside checkSurveyStatus in webservice caller');
    return this.http.get(endpoint + 'checkSurveyStatus').pipe(map(this.extractData));
}

 public getAllStaticContent(): Observable<any> {
    // console.log('Inside getAllStaticContent in webservice caller');
    return this.http.get(endpoint + 'getAllStaticContent').pipe(map(this.extractData));
}

public getQuestionByTheme(): Observable<any> {
    // console.log('Inside getQuestionByTheme in webservice caller');
    return this.http.get(endpoint + 'getQuestionByTheme').pipe(map(this.extractData));
}

public getMunicipalityList(): Observable<any> {
    // console.log('Inside getMunicipalityList in webservice caller');
    return this.http.get(endpoint + 'getMunicipalityList').pipe(map(this.extractData));
}
//
public getProvienceList(): Observable<any> {
    // console.log('Inside getProvienceList in webservice caller');
    return this.http.get(endpoint + 'getProvienceList').pipe(map(this.extractData));
}
//
public getAllSchoolNames(request: {code: any; }): Observable<any> {
    // console.log('Inside getAllSchoolNames in webservice caller');
    return this.http.get(endpoint + 'getAllSchoolNames?code=' + request.code).pipe(map(this.extractData));
}
//
public verifyReportKey(request: {key: any; }): Observable<any> {
    return this.http.get(endpoint + 'verifyReportKey?reportKey=' + request.key).pipe(map(this.extractData));
}
getDownloadData(useRequest: { startDate: Date; endDate: Date; schoolCode: any; municipalityCode: any;
provienceCode: any; report_key: any }): Observable<any> {
   // console.log('Inside API Service for getDownloadData');
    const isoStartDate = new Date(useRequest.startDate).toISOString();
    const isoEndDate = new Date(useRequest.endDate).toISOString();
    return this.http.get(endpoint
      + 'downloadDump?startDate=' + isoStartDate
      + '&endDate=' + isoEndDate
      + '&schoolCode=' + useRequest.schoolCode
      + '&municipalityCode=' + useRequest.municipalityCode
      + '&provienceCode=' + useRequest.provienceCode
      + '&report_key=' + useRequest.report_key, { responseType: 'blob' as 'json'}).pipe(
      retry(0), catchError(this.handleError<any>('getDownloadData')));
  }
  //
/*public getDownloadData(userRequest): Observable<any> {
    console.log('Inside API Service for getDownloadData');
    return this.http.post<any>(endpoint + 'downloadDump', JSON.stringify(userRequest), { responseType: 'blob' as 'json' }).pipe(
      retry(0), catchError(this.handleError<any>('getDownloadData')));
  }*/
//
saveUserResponse(response: { end_time: any; user_response: any; start_time: any; }): Observable<any> {
    // console.log('Inside saveUserResponse in webservice caller with response as below : ');
    // console.log(response);
    return this.http.post<any>(endpoint + 'saveUserResponse', JSON.stringify(response)).pipe(
      // tslint:disable-next-line:no-shadowed-variable
    tap((response) => console.log(`added new response w/ time=${response}`)),
    catchError(this.handleError<any>('saveUserResponse'))
  );
  }

 private handleError<T>(operation = 'operation', result?: T) {
    // console.log('Inside handleError in webservice caller');
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
}
