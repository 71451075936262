import { feedback } from './../feedback';
import { Questions } from './../Questions';
import { FormsModule } from '@angular/forms';
import { QuestionsComponent } from './../questions/questions.component';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Component, OnInit, Input, Output } from '@angular/core';
import { Router} from '@angular/router';
import { AdminService } from '../admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { DataShareService } from '../data-share.service';
import { Subject } from 'rxjs';
import { keyframes } from '@angular/animations';




declare var $:any;


@Component({
  selector: 'app-appexit',
  templateUrl: './appexit.component.html',
  styleUrls: ['./appexit.component.scss']
})
export class AppexitComponent implements OnInit {
  public Ufeedback: any = [];
  public fb_qid: any;
  public fb: {};
  public fb1: {};
  public fb2: {};
  public fb3: {};
  public fb4: {};
  public fb5: {};
  public generalFB: string = '!!!';
  public generalFB_Enterpurner: string = '!!!';
  public generalFB_EnterpurnerEM: string = '!!!';
  public feedbackFound = false;
  public slang: any;
  public fbScoreAv: number = 0;
  public fbScoreAv_Enterpurner: number = 0;
  stars: number[] = [0, 0, 0, 0, 0]; //five empty stars
  rating: number;
  iconClass = {
    0: 'far fa-star',
    0.5: 'fas fa-star-half-alt',
    1: 'fas fa-star'
  }
  gender: any;

  constructor(public adminApi : AdminService, public _router : Router, private sharedata: DataShareService) { 
    let _t =  localStorage.getItem('UL') ;  
    const _txt = {ENG: 'Generating Feedback...', FIN: 'Tuottaa Palautetta...', SWE: 'Feedbackresultat...'}; const txt = _txt[_t];
    $.LoadingOverlay("show", {
      background:"rgba(125, 170, 110, 0.5)",text:txt, textAutoResize : true, size : 12 }); $.LoadingOverlay("resize")
  }

  ngOnInit() {     
    $("*").find("input[type='radio']").click(function(){
   const stars = $("*").find("input[type='radio']:checked").val(); //console.log(stars)
  showStars(1)
    }) 
    this.slang = this.getLang(); 
    this.loadFeedback()
    if (!localStorage.getItem('UL') && !localStorage.getItem('UR')) {
    $.LoadingOverlay("hide", true) ;
    this.getHome()
    }

  }
  ngOnChanges(){
    this.fillStars(5);
  }
  fillStars(x){
    var starsToFill = Math.round(x * 2)/2; //round to nearest 0.5
    var i = 0;
    while(starsToFill > 0.5){
      this.stars[i] = 1;
      i++;
      starsToFill--;
    }
    if(starsToFill === 0.5){
      this.stars[i] = 0.5;
    }
  }
  // UL : FIN
    // UR : {"SPA":[766,"Sottunga"],"SSA":"3","SSB":"3","CBA":["2"],"SSC":"9","SSD":"9","CBB":["4"],"SSE":"3","SSF":"3","LSA":"2","LSB":"2","LSC":"3","LSD":"2","LSE":"3","LSF":"3","LSH":"3","LSG":"2","LSI":"3","LSJ":"2","LSK":"3","LSL":"2","LSM":"3","LSN":"2","LSO":"3","LSP":"3","LSQ":"2","LSR":"3","LSS":"3","LST":"3","LSU":"3","LSV":"2","LSW":"3","LSX":"2","LSY":"3","LSZ":"3"}

    getHome = () => {this._router.navigate(['/home']);localStorage.removeItem('UL');localStorage.removeItem('UR');}
getLocalfb = () => {return JSON.parse(localStorage.getItem('UR'))}
localFeedbackData = this.getLocalfb()
loadFeedback() {
  this.adminApi.userFeedback().subscribe((fbdata: {}) => {
   const qid = this.getQids(fbdata) ;  
   const lid = this.getlocalQid(qid) ;  
  let l = localStorage.getItem('UL'); let _l: string = '';
  if (l == 'ENG'){ _l = 'English';} if (l == 'SWE'){ _l = 'Swedish';} if (l == 'FIN'){ _l = 'Finnish';}
   let t_fb = this.extractFeedback(lid, _l, fbdata )
   this.fb = t_fb; this.fb1 = t_fb[0];this.fb2 = t_fb[1];this.fb3 = t_fb[2];this.fb4 = t_fb[3];this.fb5 = t_fb[4];
     })
  }
  
  extractFeedback(_a: {}, _c: string, _b: {}){
    let tmp: any = [] ; 
    for (const fb_levels of Object.keys(_a)){  
      for(const val in _b){ 
        if (_b[val].feedback_calculation){ 
          if (String(_b[val].nine_graders_feedback_question_list) == String(fb_levels)){ let lb = ' ';
            tmp.push(_b[val].nine_graders_feedback_parameters[_a[fb_levels]].nine_graders_feedback_text[_c]+lb)}
            if (String(_b[val].nine_graders_feedback_question_list) == 'LSZB' && this.fbScoreAv != 0){
              this.generalFB = _b[val].nine_graders_feedback_parameters[(this.fbScoreAv -1)].nine_graders_feedback_text[_c]
            }
            if (String(_b[val].nine_graders_feedback_question_list) == 'LSZD' && this.fbScoreAv != 0){
              this.generalFB_Enterpurner = _b[val].nine_graders_feedback_parameters[0].nine_graders_feedback_text[_c]
            }
            if (String(_b[val].nine_graders_feedback_question_list) == 'LSZC' && this.fbScoreAv != 0){
              this.generalFB_EnterpurnerEM = _b[val].nine_graders_feedback_parameters[0].nine_graders_feedback_text[_c]
            }            
         }
      }
    } 
    tmp.push(this.generalFB_EnterpurnerEM)
  sessionStorage.setItem('UFB','feedback_s_locked');
 $.LoadingOverlay("hide", true) ;
    return tmp
  }

getQids(x: {}) {
  const fb = x; const O = [];
  for (const f in fb){
    if (fb[f].feedback_calculation){
      O.push(fb[f].nine_graders_feedback_question_list);
        }
    }
    return O
  }
getlocalQid(xx: any){
if (localStorage.getItem('UR') && localStorage.getItem('UL')) {
this.feedbackFound = true; let fb_level = {}; let t_level:number;
const ls = JSON.parse(localStorage.getItem('UR'))
this.gender = ls['SSA']; let t_l:number = 0;
for (const key01 of Object.keys(ls)) { 
  for (const key02 in xx){ 
    if (key01 == xx[key02]) { 
    // convert actual feedbback to levels
    if (ls[key01] >= 0 && ls[key01] <3)  {t_level = 0; this.fbScoreAv = 1}
    if (ls[key01] >= 3 && ls[key01] <4)  {t_level = 1; this.fbScoreAv = 2}
    if (ls[key01] >= 4 && ls[key01] <6)  {t_level = 2; this.fbScoreAv = 3}
    //console.log(`${xx[key02]}: ${ls[key01]}`) // original feedback, 
    fb_level[xx[key02]] = t_level; 
    t_l += t_level
    }
  }
}
var ent_sum_score = ((Number(ls['LSZ']) + Number(ls['LSU']))/2).toFixed(2); 
this.fbScoreAv_Enterpurner = Number(ent_sum_score); 
if (this.fbScoreAv_Enterpurner >= 0 && this.fbScoreAv_Enterpurner <2.99) fb_level['LSU'] = 0;
if (this.fbScoreAv_Enterpurner >= 3 && this.fbScoreAv_Enterpurner <3.99) fb_level['LSU'] = 1;
if (this.fbScoreAv_Enterpurner >= 4 && this.fbScoreAv_Enterpurner <5) fb_level['LSU'] = 2;
showStars(t_l)
  return fb_level
   }
} 

sendmail(){
  if(checkmail()) { 
  let l = localStorage.getItem('UL'); let _l: number = 0;
  if (l == 'ENG'){ _l = 3;} if (l == 'SWE'){ _l = 2;} if (l == 'FIN'){ _l = 1;}
    let email = $('*').find('#signuptop').val();
    var ary = {
      "report1": String(this.fb1),
      "report2": String(this.fb2),
      "report3": String(this.fb3),
      "report4": String(this.fb4),
      "report5": String(this.fb5),
      "email": email   
      }
    var dta = JSON.stringify(ary) ; 
    this.adminApi.emailReport(_l,dta).subscribe((report: any) => {
      if (report == 'sent!') {
        $('.cst-error').text('Email sent').show().addClass('cst-success').removeClass('cst-danger');
    this.getHome()
  } 

})

}
}
 //getLang = () => {return (this.sharedata.currentLanguage.subscribe(language => this.selectedLanguage = language ));}
 getLang = () => {let lng: string = localStorage.getItem('UL'); return lng}
} // class



function checkkk() {
  if (localStorage.getItem('UL') || localStorage.getItem('UR')){
    localStorage.removeItem('UL');
    localStorage.removeItem('UR');
    location.reload();
  }
  if (!localStorage.getItem('UL') && !localStorage.getItem('UR')) this.getHome()
}

function showStars_(x) {
  const _x =((x/3)+1) ; 
  if (_x >= 0 && _x < 1.5) { $('*').find('#star3').attr('checked', 'checked'); }
  if (_x >= 1.5 && _x < 2) { $('*').find('#star3half').attr('checked', true);}
  if (_x >= 2 && _x < 2.5) { $('*').find('#star4').attr('checked', true);}
  if (_x >= 2.5 && _x < 2.9) { $('*').find('#star4half').attr('checked', 'checked');}
  if (_x >= 3) { $('*').find('#star5').attr('checked', true);}

}
function showStars(x) {
   { $('*').find('#star5').attr('checked', true);}

}

function checkmail() {
 var email = $('*').find('#signuptop').val()
if(!email)
{
   $('.cst-error').text('enter  email').show().addClass('cst-danger').removeClass('cst-success');
   email.focus;
   return false;
   
}
  var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!filter.test(email)) {
  $('.cst-error').text('invalid email').show().addClass('cst-danger').removeClass('cst-success');
  email.focus;
  return false;
}
else{
  $('.cst-error').text('Wait...').show().addClass('cst-success').removeClass('cst-danger');  
  return true
 }

}