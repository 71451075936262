<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate"><meta http-equiv="Pragma" content="no-cache"><meta http-equiv="Expires" content="0">

<ul class="nav nav-pills nav-fill" role="tablist" id="nav-tab">
  <li class="nav-item " id="l1" #l1>
    <a class="nav-link" data-toggle="tab" href="#welcome" role="tab" > Help </a>
  </li>
  <li class="nav-item" id="l2" #l2>
    <a class="nav-link"  href="#questionmanagment" data-toggle="tab" role="tab" > Questions </a>
  </li>  
  <li class="nav-item" id="l3" #l3>
    <a class="nav-link" href="#feedback" data-toggle="tab" role="tab" > Feedback </a>
  </li>
  <li class="nav-item" id="l4" #l4>
    <a class="nav-link" href="#reports" data-toggle="tab" role="tab" > Reports </a>
  </li>

    <button class="nav-link" (click)="adminApi.logoutU()"> Logout </button>
  
</ul>

<div class="tab-content" id="tabs">

  <div class="col-md-12 row-no-gutters table-responsive row container-fluid tab-pane" id="questionmanagment">
    <table class="table table-striped table-hover" id=datatable #datatable >
        <thead >
            <tr>
              <th> MEP Questions: <button (click)="loadQ()" data-toggle="tooltip" title="Click to reload"> &#8634; </button> <span id="info2" class="info2"></span>  </th>
              <th><select class="form-select" id="pageMenu" [(ngModel)]="searchText" ><option selected>Select Page...</option><option value= 10>Page 1</option><option value= 20>Page 2</option><option value= 30>Page 3</option><option value= 40>Page 4</option> </select></th>
            </tr>
        </thead>
      <tbody>
          <tr *ngFor="let question of Questions | slice:(searchText - 10):searchText; index as a"  class="qureshi01">
              <td class="form11" id ={{a}}><span class="info1" id="info" >{{info_text}}</span><br>
                <p style="color: rgba(0, 0, 255, 0.874)" *ngIf="TargetQuestion != a"><b>(Finnish): </b> {{question.mtee_question_text.FIN}}<br><i><mat-icon style="font-size:16px;" >directions_run</mat-icon>{{question.mtee_question_instruction.FIN}} </i></p><p style="color: rgba(0, 128, 0, 0.874)" *ngIf="TargetQuestion != a"> <b>(Swedish): </b> {{question.mtee_question_text.SWE}}<br><i><mat-icon style="font-size:16px;" >directions_run</mat-icon> {{question.mtee_question_instruction.SWE}} </i></p><p style="color: rgba(255, 0, 0, 0.874)" *ngIf="TargetQuestion != a"><b>(English): </b> {{question.mtee_question_text.ENG}} <br><i> <mat-icon style="font-size:16px;" >directions_run</mat-icon>{{question.mtee_question_instruction.ENG}} </i></p>

               <p *ngIf="EditMode && TargetQuestion == a" id="qf"><input type="text" autocomplete="off"  id="q_fin" autofocus style="size:100%;width:100%;color:blue;" name="q_fin" value={{question.mtee_question_text.FIN}} /> </p><p *ngIf="EditMode && TargetQuestion == a" id="qs"> <input type="text" autocomplete="off"  id="q_swe" name="q_swe" style="size:100%;width:100%;color:green;" value={{question.mtee_question_text.SWE}} /></p><p *ngIf="EditMode && TargetQuestion == a" id="qe"> <input type="text" autocomplete="off"  id="q_eng" name="q_eng" style="size:100%;width:100%;color:red;" value={{question.mtee_question_text.ENG}} /> </p>
              <p id="ins" *ngIf="EditMode && TargetQuestion == a "><mat-icon style="font-size:16px;">directions_run</mat-icon> Instructions: <input type="text" autocomplete="off"  id="ins_fin" style="size:100%;width:100%;color:blue;" name="ins_fin" value={{question.mtee_question_instruction.FIN}} data-toggle="tooltip" title="Add instruction in Finnish language" placeholder="Finnish" /><input type="text" autocomplete="off"  id="ins_swe" name="ins_swe" style="size:100%;width:100%;color:green;" value={{question.mtee_question_instruction.SWE}} data-toggle="tooltip" title="Add instruction in Swedish language" placeholder="Swedish" /><input type="text" autocomplete="off"  id="ins_eng" name="ins_eng" style="size:100%;width:100%;color:red;" value={{question.mtee_question_instruction.ENG}} data-toggle="tooltip" title="Add instruction in English language" placeholder="English" /></p>
              </td>


              <td id="buttons" #buttons><b>Question: {{((a+1)+(searchText - 10))}}.<br>{{question.mtee_question_id}}</b><br><span *ngIf="!EditMode" class="edit_question" (click)="editData(a)"><button mat-button><mat-icon style="font-size:60px;"> edit_note </mat-icon><br><br><b>EDIT</b></button></span><span *ngIf="EditMode && TargetQuestion == a" class="save_question" ><button mat-button (click)="saveData(a, question._id.$oid)"><mat-icon style="font-size:60px;" data-toggle="tooltip" title="Click here to Save">save</mat-icon><br><br> </button> <br><br><button mat-button (click)="cancleUD(a)"><mat-icon style="font-size:60px;" data-toggle="tooltip" title="Click here to Cancle and go back">clear</mat-icon><br><br></button><br></span>
                <span *ngIf="!EditMode" style="color: rgb(71, 62, 62); float: right; padding: 10px;" data-toggle="tooltip" title="Questions are available in multiple languages: &#013; &#10; Finnish :  The text in Blue color is Finnish language &#013; &#10; Swedish : The text in green color is Swedish language &#013; &#10; English : The text in red color is English language &#013; &#10; Click on the Edit button to update Question and Instructions"><mat-icon>help_outline</mat-icon></span>
              </td>
          </tr>
      </tbody>
    </table>
  </div>



<!--Municipalities PART-->
  <div class="tab-pane" id="welcome"> 
   <div class="lead"> <p>The top bar shows different TABS, each of these tabs has its category and function, click on each tab to go to that section.</p>
    <p><b>1: Help </b>
    This tab shows help, also login user information, and the option to change the current password
    </p>
    <p><b>2: Questions</b>
    This section allows you to change question text by clicking on the EDIT button in front of each question. Saving the question will have an immediate effect and the question will be updated. Only one question can be edited at a time.
    Some questions have instructions text, that can also be edited alongside the question.
    </p>
    <p><b>3: Feedback</b>
    This text is displayed at the end of the survey result, to edit that feedback you can click on the EDIT button in front of each feedback. In front of each feedback is also a reference of the question which is taken into account for the feedback.
    Actual feedback calculation and mechanism are embedded into the code and can not be changed from the admin area. The question which is taken into account can only be made active by using the MongoDB atlas.
    </p>
     <p><b>4: Reports</b>
    This section creates the report for a variety of periods and options. Reports are downloaded in CSV format but if you click on convert to excel it can be converted. The summary of the report can be generated in different languages.
     </p></div>
<!--
    <div pDraggable="dd">Draggable Div</div>

    <div pDroppable="dd">Droppable Div</div>
    -->
    
  </div>

  <!--FEEDBACK PART-->
  <div class=" row-no-gutters table-responsive container-fluid tab-pane" id="feedback">
    <table class="table table-striped table-hover" id=feedbacktable #feedbacktable >
      <thead >
        <tr>
          <th> Feedback Managment : <button (click)="loadFeedback()" data-toggle="tooltip" title="Click to reload"> &#8634; </button></th> <th> <span id="fbinfo1" class="fbinfo1"></span></th>
        </tr>
      </thead>
        <tbody>
          <tr *ngFor="let fb of feedback; index as b">
            <td class="form22" >  
                <p *ngFor="let fb0 of fb.nine_graders_feedback_parameters; index as c" ><span style="color:red; font-size:medium"> <hr style="height:2px;border-width:0;color:gray;background-color:gray">
                  For {{fb0.nine_graders_feedback_min}} To {{fb0.nine_graders_feedback_max}} Feedback Score</span><br>
                <br><span *ngIf="TargetFB != b" >English:{{fb0.nine_graders_feedback_text?.English}}</span><span *ngIf="FB_EditMode && TargetFB == b" id={{b}}><label for="fb_en">Feedback in English </label><span *ngIf="fb.feedback_calculation == true" style="color:red; font-size:medium"  data-toggle="tooltip" title="Feedback will appear with English language selection"> (This feedback is active) </span><textarea class="form-control {{c}}" id="fb_en" rows="3">{{fb0.nine_graders_feedback_text.English}}</textarea></span>
                <br><span *ngIf="TargetFB != b" >Finnish:{{fb0.nine_graders_feedback_text?.Finnish}}</span><span *ngIf="FB_EditMode && TargetFB == b" id={{b}}><label for="fb_fn">Feedback in Finnish </label><span *ngIf="fb.feedback_calculation == true" style="color:red; font-size:medium"  data-toggle="tooltip" title="Feedback will appear with Finnish language selection"> (This feedback is active) </span><textarea class="form-control {{c}}" id="fb_fn" rows="3">{{fb0.nine_graders_feedback_text.Finnish}}</textarea></span>
                <br><span *ngIf="TargetFB != b" >Swedish:{{fb0.nine_graders_feedback_text?.Swedish}}</span><span *ngIf="FB_EditMode && TargetFB == b" id={{b}}><label for="fb_sw">Feedback in Swedish </label><span *ngIf="fb.feedback_calculation == true" style="color:red; font-size:medium"  data-toggle="tooltip" title="Feedback will appear with Swedish language selection"> (This feedback is active) </span><textarea class="form-control {{c}}" id="fb_sw" rows="3">{{fb0.nine_graders_feedback_text.Swedish}}</textarea></span>
                </p>
            </td>
             <h2><span *ngIf="fb.feedback_calculation == true" style="background: rgba(200, 198, 198, 0.753); color:red"  data-toggle="tooltip" title="This question is active for feedback"> {{fb.nine_graders_feedback_question_list}}</span> </h2> <h2><span *ngIf="fb.feedback_calculation == false" style="background: rgba(200, 198, 198, 0.753); color:green"  data-toggle="tooltip" title="this question is not active for feedback"> {{fb.nine_graders_feedback_question_list}}</span></h2><br><span *ngIf="fb.feedback_calculation == true" style="background: rgba(200, 198, 198, 0.753); color:red"  data-toggle="tooltip" title="This question is active for feedback"> ACTIVE </span>
            <td class="form23" ><span class="fbinfo2" id="fbinfo2" > </span><br>
              <span *ngIf="TargetFB != b" (click)="editFB(b)"><button mat-button><mat-icon style="font-size:60px;"> edit_note </mat-icon><br><br><b>EDIT</b></button></span>
              <span *ngIf="FB_EditMode && TargetFB == b" class="save_feedback" ><button mat-button (click)="saveFB(b, fb._id.$oid)"><mat-icon style="font-size:60px;" data-toggle="tooltip" title="Click here to Save">save</mat-icon><br><br> </button> <br><br><button mat-button (click)="cancleFB()"><mat-icon style="font-size:60px;" data-toggle="tooltip" title="Click here to Cancle and go back">clear</mat-icon><br><br></button><br></span>
            </td>
            </tr>
        </tbody>
    </table>
  </div>

<!--REPORT PART START-->
  <div class="tab-pane row-no-gutters container-fluid" id="reports">
    <mat-card class="report-cards"> <mat-card-title>Reports</mat-card-title>
      <form [formGroup]="formReport">
        <div class="formReport" id="formReport">
          <div class=""> 
          <mat-form-field appearance="fill">
            <mat-label>Select Start Date</mat-label>
            <input matInput [min]="minStartDate" [max]="maxStartDate" [matDatepicker]="picker1" (dateChange)="selectedStartDate($event)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker1></mat-datepicker>
          </mat-form-field>                          
          <mat-form-field appearance="fill">
            <mat-label>Select End Date</mat-label>
            <input matInput [min]="minEndDate" [max]="maxEndDate" [matDatepicker]="picker2" (dateChange)="selectedEndDate($event)">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker2></mat-datepicker>
          </mat-form-field>            <input type="checkbox" style="width: 20px;Height: 20px; margin-left:30px;" id="downloadXL"> <label for="downloadXL" style="font-size:20px;margin-left:20px" > Convert to Excel </label>
                              <!--        <input type="checkbox" style="width: 20px;Height: 20px; margin-left:30px;" id="createChart"> <label for="createChart" style="font-size:20px;margin-left:20px" > Create Charts </label> --> 
          
          </div>
          <div class=""> 
            <mat-form-field appearance="fill" id="rForm">
              <mat-label>Provience</mat-label>
              <mat-select (selectionChange)="onProvienceSelect($event)" placeholder=""[(value)]="selectedProvience" >
                <mat-option value="all"> All </mat-option>
                <!--   <input (keyup)="onKeyA($event.target.value)"> -->
                <mat-option *ngFor="let provience of provienceList" [value]="provience">
                  {{provience}}
                </mat-option> 
              </mat-select>
            </mat-form-field>
          <mat-form-field appearance="fill" >
              <mat-label>Municipality</mat-label>
              <mat-select (selectionChange)="onMunicipalitySelect($event)" placeholder=""[(value)]="selectedMunicipality1" required>
                <mat-option value="all"> All </mat-option>
                <mat-option *ngFor="let municipality of municipalityListShow1" [value]="municipality">
                  {{municipality}}
                </mat-option>
              </mat-select> 
              </mat-form-field>
        <!--      <input type="checkbox" *ngIf="formReportRequest.municipalityCode != ''" style="width: 20px;Height: 20px; margin-left:30px;" id="sumOnly"> <label for="sumOnly" *ngIf="formReportRequest.municipalityCode != ''" style="font-size:20px;margin-left:20px" > Summary only </label> -->
              <button *ngIf="formReportRequest.municipalityCode != ''" mat-button (click) = "downloadData()"><mat-icon style="font-size:60px;"> download </mat-icon><br><br></button>
             
              <input type="checkbox" *ngIf="formReportRequest.municipalityCode != ''"  style="width: 20px;Height: 20px; margin-left:20px;" id="rlSwe" name="rlSwe" disabled><label for="rlSwe" *ngIf="formReportRequest.municipalityCode != ''" style="font-size:20px;margin-left:10px" > SW</label>
              <input type="checkbox" *ngIf="formReportRequest.municipalityCode != ''"  style="width: 20px;Height: 20px; margin-left:20px;" id="rlFin" name="rlFin" disabled><label for="rlFin" *ngIf="formReportRequest.municipalityCode != ''" style="font-size:20px;margin-left:10px" >FI</label>
              
            </div>
            <!--<button (click)="Reset_report()">Reset and Reload</button> -->
            <div class = 'info2'>{{errorMsg}}</div>
            </div>
        </form>
       <!--<div *ngIf="formReportRequest.municipalityCode == 'all' && formReportRequest.provienceCode != 'all'  && formReportRequest.provienceCode != '' "> <input type="checkbox" style="width: 20px;Height: 20px;margin-left:50px" id="countyOnly" [(ngModel)]="countyOnly" > <label for="countyOnly" style="font-size:20px;margin-left:50px" > All Municipalities of {{formReportRequest.provienceCode}} </label> 
      </div>
      <div *ngIf="formReportRequest.municipalityCode != '' "> <input type="checkbox" style="width: 30px;Height: 24px;" id="summary" [(ngModel)]="summaryReport" > <label for="summary" style="font-size:20px;" > Generate Summary </label></div> -->
       
      </mat-card>  

      <mat-card class="report-cards" *ngIf="summaryReport"> <mat-card-title> Summary of results <mat-icon style="font-size:40px;"> bar_chart </mat-icon></mat-card-title>
         <div class="row">
          <table class="table table-bordered">
            <thead class="thead-dark">
            <tr>
             <th>Provience </th>
             <th>Municipality </th>
             <th>Start Date </th>
             <th>End Date </th>
             <th>Total Results </th>
            </tr>
           </thead>
           <tbody>
            <tr>
             <td>{{summary.County}}</td>
             <td>{{summary.Municipality}}</td>
             <td>{{summary['sdate']}}</td>
             <td>{{summary['edate']}}</td>
             <td>{{summary.results}}</td>
            </tr>
           </tbody>
          </table>
          <div class="col-md-6 col-sm-6">
            <p-chart type="doughnut" [width]="180" [height]="180" [data]="chartDataG"></p-chart>
          </div>
          <div class="col-md-6 col-sm-6">
            <p-chart type="bar" [width]="180" [height]="200" [data]="chartDataL"></p-chart>
          </div>
      </div> 
    </mat-card>
</div>
  </div><!--main-->

  <!--Modal-->
      <div class="modal fade " id="B_modal" tabindex="-1" role="dialog">
      <div class="modal-dialog text-shadows">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="modalLabel"></h3>
            <button type="button" class="close" data-dismiss="modal" ><img src="" width="84px" height="84px"></button>
          </div>
          
          <h4 id="details"></h4>

        </div>
        
      </div>

      </div>
